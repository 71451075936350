.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #666666;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.hidden {
  opacity: 0;
  transform: translateX(0);
}


/* Apply the animation to html, body */
html, body {
  background-color: #ffffff; /* Starting color */
  font-family: 'Montserrat', sans-serif;
  animation: backgroundTransition 40s ease-in-out infinite;
  font-weight: 500;
}



.hidden {
  opacity: 0;
  transform: translateX(0);
  overflow-x: hidden;
}

.slide-in-left {
  animation: slideLeft 2.5s forwards ease-out;
}

.slide-in-right {
  animation: slideRight 2.5s forwards ease-out;
}

@keyframes slideLeft {
  0% {
      transform: translate(-20%, -35%);
      opacity: 0;
  }
  50% {
      transform: translate(-20%, -35%);
      opacity: 0;
  }
  100% {
      transform: translate(0, 0);
      opacity: 1;
  }
}


@keyframes slideRight {
  0% {
    transform: translate(10%, 15%);
    opacity: 0;
}
50% {
    transform: translate(10%, 15%);
    opacity: 0;
}
100% {
  transform: translate(0, 0);
  opacity: 1;
}
}





.hover-effect {
  position: relative; 
  text-decoration: none; 
}

.hover-effect:hover {
  text-decoration: underline; 
}

/*
.hover-effect::before,
.hover-effect::after {
  content: '';           
  position: absolute;    
  opacity: 0;           
  transition: opacity 0.1s ease-in-out;  
}

.hover-effect::before {
  content: '<';          
  left: -20px;           
  top: 50%;
  transform: translateY(-50%);
}

.hover-effect::after {
  content: '>';          
  right: -20px;          
  top: 50%;
  transform: translateY(-50%);
}


.hover-effect:hover::before,
.hover-effect:hover::after {
  opacity: 1;            
}
*/


.nav1 {
  animation: nav1Animation 3.8s forwards ease-out;
}

@keyframes nav1Animation {
  0% {
      opacity: 0;
  }
  70% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


.nav2 {
  animation: nav2Animation 4.2s forwards ease-out;
}

@keyframes nav2Animation {
  0% {
      opacity: 0;
  }
  70% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.nav3 {
  animation: nav3Animation 4.6s forwards ease-out;
}

@keyframes nav3Animation {
  0% {
      opacity: 0;
  }
  70% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


.nav4 {
  animation: nav4Animation 5.0s forwards ease-out;
}

@keyframes nav4Animation {
  0% {
      opacity: 0;
  }
  70% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


.nav5 {
  animation: nav5Animation 4.2s forwards ease-out;
}

@keyframes nav5Animation {
  0% {
      opacity: 0;
  }
  70% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}





/* Large screen */
.homepage-animation-square-one-large {
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-one;
  background: #aea7f7; /*ffebaa #d29300*/
  height: 1299px;
  width: 1299px;
  left: 60%;
  opacity: 70%;
  top: 35%;
  animation-iteration-count: infinite;
  filter: blur(200px);
  overflow-x: hidden;
  overflow-y: hidden;
  

}




.homepage-animation-square-one-dot-one-large {
  filter: blur(100px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-six;
  background: #d580ff;
  height: 599px;
  width: 599px;
  left: 100%;
  opacity: 40%;
  top: 1vh;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-two-large {
  position: absolute;
  z-index: 2; 
animation-direction: reverse;
animation-duration: 24s;
animation-name: square-two;
background: #67d7fa; /*#d29300 #344363*/
height: 499px;
left: 20%;
opacity: 60%;
top: 10%;
width: 500px;
animation-iteration-count: infinite;
filter: blur(100px);
overflow-x: hidden;
overflow-y: hidden;
}


/* small screen */

.homepage-animation-square-one-small {
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-one;
  background: #aea7f7; /*ffebaa #d29300*/
  height: 799px;
  width: 800px;
  left: 60%;
  opacity: 60%;
  top: 35%;
  animation-iteration-count: infinite;
  filter: blur(200px);
  overflow-x: hidden;
  overflow-y: hidden;

}

.homepage-animation-square-one-dot-one-small {
  filter: blur(100px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-six;
  background: #d580ff;
  height: 399px;
  left: 100%;
  opacity: 50%;
  top: 1vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-two-small {
  position: absolute;
  z-index: 2; 
animation-direction: reverse;
animation-duration: 24s;
animation-name: square-two;
background: #67d7fa; /*#d29300 #344363*/
height: 299px;
left: 20%;
opacity: 60%;
top: 10%;
width: 300px;
animation-iteration-count: infinite;
filter: blur(100px);
overflow-x: hidden;
overflow-y: hidden;
}

/* all screens */

.homepage-animation-square-three {
  position: absolute;
  z-index: 2; 
  filter: blur(140px);
  animation-duration: 24s;
  animation-name: square-three;
  background: #4fbfff; /*#ff9500*/
  height: 399px;
  left: 60%;
  opacity: 60%;
  top: 10%;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-three-dot-one {
  filter: blur(100px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-five;
  background: #4fbfff;
  height: 399px;
  left: 5%;
  opacity: 60%;
  top: 30vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-four {
  filter: blur(140px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-four;
  background: #4fbfff;
  height: 399px;
  right: 5%;
  opacity: 60%;
  top: 102vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-five {
  filter: blur(140px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-five;
  background: #4fbfff;
  height: 399px;
  left: 5%;
  opacity: 60%;
  top: 104vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-six {
  filter: blur(140px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-six;
  background: #aea7f7;
  height: 399px;
  left: 6%;
  opacity: 60%;
  top: 104vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-seven {
  filter: blur(140px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-four;
  background: #aea7f7;
  height: 399px;
  right: 5%;
  opacity: 60%;
  top: 210vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-eight {
  filter: blur(140px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-five;
  background: #aea7f7;
  height: 399px;
  left: 5%;
  opacity: 60%;
  top: 214vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-nine {
  filter: blur(140px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-six;
  background: #3676ff;
  height: 399px;
  left: 6%;
  opacity: 60%;
  top: 214vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-ten {
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-one;
  background: #4fbfff ; /*ffebaa #d29300*/
  height: 799px;
  left: 60%;
  opacity: 60%;
  top: 315vh;
  width: 800px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  filter: blur(200px);
  overflow-y: hidden;
}

.homepage-animation-square-eleven {
  position: absolute;
  z-index: 2; 
animation-direction: reverse;
animation-duration: 24s;
animation-name: square-two;
background: #4fbfff; /*#d29300*/
height: 299px;
left: 20%;
opacity: 60%;
top: 301vh;
width: 300px;
animation-iteration-count: infinite;
overflow-x: hidden;
filter: blur(100px);
overflow-y: hidden;
}

.homepage-animation-square-twelve {
  position: absolute;
  z-index: 2; 
  filter: blur(140px);
  animation-duration: 24s;
  animation-name: square-three;
  background: #4fbfff; /*#ff9500*/
  height: 399px;
  left: 60%;
  opacity: 49%;
  top: 310vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-thirteen {
  filter: blur(100px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-five;
  background: #aea7f7;
  height: 399px;
  left: 5%;
  opacity: 60%;
  top: 330vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-fourteen {
  filter: blur(140px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-four;
  background: #aea7f7;
  height: 399px;
  right: 5%;
  opacity: 60%;
  top: 360vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-fifteen {
  filter: blur(140px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-five;
  background: #8a4fff;
  height: 399px;
  left: 5%;
  opacity: 50%;
  top: 364vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage-animation-square-sixteen {
  filter: blur(100px);
  position: absolute;
  z-index: 2; 
  animation-duration: 24s;
  animation-name: square-six;
  background: #4fbfff;
  height: 399px;
  left: 6%;
  opacity: 60%;
  top: 364vh;
  width: 399px;
  animation-iteration-count: infinite;
  overflow-x: hidden;
  overflow-y: hidden;
}


@media screen and (min-height: 1050px) {
  .homepage-animation-square-one-large {
    width: 2598px;
    height: 2598px;
    filter: blur(1500px);
    opacity: 46%;
  }

  .homepage-animation-square-two-large {
    width: 1000px;
    height: 998px;
    filter: blur(600px);
    opacity: 46%;
  }

  .homepage-animation-square-one-small {
    width: 1600px;
    height: 1598px;
    filter: blur(800px);
    opacity: 46%;
  }

  .homepage-animation-square-one-dot-one-small {
    width: 798px;
    height: 798px;
    filter: blur(400px);
    opacity: 46%;
  }

  .homepage-animation-square-two-small {
    width: 600px;
    height: 598px;
    filter: blur(300px);
    opacity: 46%;
  }

  .homepage-animation-square-three {
    width: 798px;
    height: 798px;
    filter: blur(280px);
    border-radius: 50%;
  }

  .homepage-animation-square-three-dot-one {
    width: 798px;
    height: 798px;
    filter: blur(200px);
    border-radius: 50%;
  }

  .homepage-animation-square-four {
    width: 798px;
    height: 798px;
    filter: blur(280px);
    border-radius: 50%;
  }

  .homepage-animation-square-five {
    width: 798px;
    height: 798px;
    filter: blur(280px);
    border-radius: 50%;
  }

  .homepage-animation-square-six {
    width: 798px;
    height: 798px;
    filter: blur(280px);
    border-radius: 50%;
  }

  .homepage-animation-square-seven {
    width: 798px;
    height: 798px;
    filter: blur(280px);
    border-radius: 50%;
  }

  .homepage-animation-square-eight {
    width: 798px;
    height: 798px;
    filter: blur(280px);
    border-radius: 50%;
  }

  .homepage-animation-square-nine {
    width: 798px;
    height: 798px;
    filter: blur(280px);
    border-radius: 50%;
  }

  .homepage-animation-square-ten {
    width: 1600px;
    height: 1598px;
    filter: blur(400px);
    border-radius: 50%;
  }

  .homepage-animation-square-eleven {
    width: 600px;
    height: 598px;
    filter: blur(200px);
    border-radius: 50%;
  }

  .homepage-animation-square-twelve {
    width: 798px;
    height: 798px;
    filter: blur(280px);
    border-radius: 50%;
  }

  .homepage-animation-square-thirteen {
    width: 798px;
    height: 798px;
    filter: blur(200px);
    border-radius: 50%;
  }

  .homepage-animation-square-fourteen {
    width: 798px;
    height: 798px;
    filter: blur(280px);
    border-radius: 50%;
  }

  .homepage-animation-square-fifteen {
    width: 798px;
    height: 798px;
    filter: blur(280px);
    border-radius: 50%;
  }

  .homepage-animation-square-sixteen {
    width: 798px;
    height: 798px;
    filter: blur(200px);
    border-radius: 50%;
  }
}




.opaque-square{
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  border-radius: 899px;
  filter: blur(200px);
  pointer-events: none;
  position: absolute;
  z-index: -2;
  overflow-x: hidden;
  overflow-y: hidden;
}



@keyframes square-one {
  0% {
  transform: translateY(-49%) translateX(-149%) rotate(-16deg);
  } 25% {
    transform: translateY(2%) translateX(-49%) rotate(32deg);
  } 49% {
    transform: translateY(-49%) translateX(49%) rotate(-24deg);
  } 75% {
    transform: translateY(5%) translateX(-49%) rotate(40deg);
  } 100% {
    transform: translateY(-49%) translateX(-149%) rotate(-16deg);
  }
}

@keyframes square-two {
  0% {
  transform: translateY(-10%) translateX(-80%);
  } 25% {
    transform: translateY(25%) translateX(70%);
  } 49% {
    transform: translateY(-5%) translateX(30%);
  } 75% {
    transform: translateY(65%) translateX(-15%);
  } 100% {
    transform: translateY(-10%) translateX(-80%);
  }
}

@keyframes square-three {
  0% {
    transform: translateY(-149%) translateX(-15%);
  } 20% {
    transform: translateY(-149%) translateX(20%);
  } 40% {
    transform: translateY(5%) translateX(-49%);
  } 60% {
    transform: translateY(-100%) translateX(-49%);
  } 80% {
    transform: translateY(-10%) translateX(5%);
  } 100% {
    transform: translateY(-149%) translateX(-15%);
  }
}

@keyframes square-four {
  0% {
  transform: translateY(49%) translateX(149%) rotate(16deg);
  } 25% {
    transform: translateY(-2%) translateX(49%) rotate(-32deg);
  } 49% {
    transform: translateY(49%) translateX(-49%) rotate(24deg);
  } 75% {
    transform: translateY(-5%) translateX(49%) rotate(-40deg);
  } 100% {
    transform: translateY(49%) translateX(149%) rotate(16deg);
  }
}

@keyframes square-five {
  0% {
  transform: translateY(-10%) translateX(-80%);
  } 25% {
    transform: translateY(25%) translateX(70%);
  } 49% {
    transform: translateY(-5%) translateX(30%);
  } 75% {
    transform: translateY(65%) translateX(-15%);
  } 100% {
    transform: translateY(-10%) translateX(-80%);
  }
}

@keyframes square-six {
  0% {
    transform: translateY(10%) translateX(80%); 
  }
  25% {
    transform: translateY(-25%) translateX(-70%);
  }
  50% {
    transform: translateY(5%) translateX(-30%);
  }
  75% {
    transform: translateY(-65%) translateX(15%);
  }
  100% {
    transform: translateY(10%) translateX(80%); 
  }
}



.inverted-text {
  mix-blend-mode: difference; /* Inverts the text color based on the background */
}